<template>
  <v-container
    id="categories-edit"
    fluid
  >
    <v-skeleton-loader
      v-if="!mounted"
      elevation="2"
      :loading="!mounted"
      type="card-heading, article@2"
    />
    <v-card
      v-if="mounted"
      color="transparent"
      flat
    >
      <v-overlay
        :value="loading"
      >
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-card-title>
        <v-icon
          class="mr-2"
        >
          mdi-file-tree-outline
        </v-icon>
        Catégorie :
        <span
          class="font-weight-light mx-2"
        >
          {{ category.short_names.fr }}
          <span class="subtitle-2">
            ({{ category.slug }})
          </span>
        </span>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col
            cols="12"
            md="12"
            sm="12"
          >
            <v-sheet
              elevation="1"
              rounded
            >
              <v-breadcrumbs
                :items="breadcrumb.parent"
              >
                <template v-slot:divider>
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
              </v-breadcrumbs>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
            sm="12"
          >
            <v-card>
              <v-card-title>
                Détail
                <v-spacer />
                <v-btn
                  text
                  small
                  color="blue lighten-1"
                  @click="modal.category.opened = true"
                >
                  Modifier
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                    sm="12"
                  >
                    <v-simple-table
                      elevation="1"
                      rounded
                      class="lh_category__details"
                    >
                      <tbody>
                        <tr>
                          <td>
                            Description
                          </td>
                          <td>
                            {{ category.description }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Nom court
                          </td>
                          <td>
                            <div
                              v-for="(names, index) in category.short_names"
                              :key="`short-name-${index}`"
                            >
                              {{ countryCodeEmoji(index) }} : {{ names }}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Nom long
                          </td>
                          <td>
                            <div
                              v-for="(names, index) in category.long_names"
                              :key="`long_name-${index}`"
                            >
                              {{ countryCodeEmoji(index) }} : {{ names }}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Cible(s)
                          </td>
                          <td>
                            <TargetChip
                              v-for="(target, index) in category.targets"
                              :key="`target-${index}`"
                              :target="target"
                              class="mr-2"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Poids (g)
                          </td>
                          <td>
                            {{ category.weight }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Priorité
                          </td>
                          <td>
                            {{ category.priority }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="6"
            sm="12"
          >
            <v-card>
              <v-card-title>Taxonomies associées</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                    sm="12"
                  >
                    <v-chip
                      v-for="(taxonomy, index) in category.taxonomies"
                      :key="`taxonomy-${index}`"
                      class="ma-1"
                    >
                      {{ taxonomy.names.fr }} ({{ taxonomy.kind }})
                    </v-chip>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <Snackbar />
    <CategoryModal
      :category-id="category.id"
      :opened.sync="modal.category.opened"
      :weight.sync="category.weight"
      :priority.sync="category.priority"
      :targets.sync="category.targets"
      :description.sync="category.description"
      :short-names.sync="category.short_names"
      :long-names.sync="category.long_names"
      @categoryUpdated="onCategoryUpdated"
    />
  </v-container>
</template>

<script>
import CategoryModal from '@/views/Admin/Categories/Edit/CategoryModal.vue'
import { countryCodeEmoji } from '@/helpers/countries'
import EventBus from '@/plugins/event-bus'
import Snackbar from '@/components/core/Snackbar.vue'
import TargetChip from '@/components/base/TargetChip.vue'

export default {
  name: 'CategoryEdit',
  components: {
    TargetChip,
    CategoryModal,
    Snackbar,
  },
  data() {
    return {
      categoryId: this.$route.params.id,
      message: {
        type: null,
        text: null,
        link: null,
      },
      mounted: false,
      request: null,
      loading: false,
      category: {},
      breadcrumb: {
        parent: [],
      },
      modal: {
        category: {
          opened: false,
        },
      },
    }
  },
  watch: {
    category () {
      this.breadcrumb.parent = []
      let category = this.category
      
      while (category !== null) {
        this.breadcrumb.parent.push({
          text: category.short_names.fr,
          href: '/admin/categories/' + category.id,
        })
        
        category = category.parent
      }
      
      this.breadcrumb.parent.reverse()
      
    },
  },
  mounted() {
    this.load(this.$route.params.id)
  },
  methods: {
    countryCodeEmoji,
    onCategoryUpdated(message) {
      if (message !== undefined && message.text !== null) {
        EventBus.$emit('SNACKBAR', { color: message.type, message: message.text, link: message.link })
      }
      this.load(this.categoryId)
    },
    cancel() {
      this.message = { type: null, text: null }
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load(id) {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      
      this.loading = true
      this.$axios.get('v3/categories/' + id, { cancelToken: axiosSource.token })
        .then((response) => {
          this.category = response.data
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.message.type = 'error'
          this.message.text = this.$i18n.t('views.category.forms.messages.save_failed', { error: error_message })
        })
        .finally(() => {
          this.request = null
          this.loading = false
          this.mounted = true
        })
    },
  },
}
</script>

<style scoped>
.lh_category__details tr td:first-child {
  font-weight: bolder;
}
</style>
